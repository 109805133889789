.footer {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);

    &-wrap {
        padding-top: gem(75);
        padding-bottom: gem(75);
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-logo {
        display: flex;
    }

    &-copy {
        color: #FFF;
        font-size: gem(32);
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        &_mobile{
            display: none;
        }
    }
}
@media (max-width: 1024px){
    .footer {
    &-wrap {
        padding-top: gem(60);
        padding-bottom: gem(60);
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: gem(30);
        margin-left: gem(20);
    }

    &-logo {
        
        align-items: flex-start;
       &-svg{
width: gem(220);
        height: gem(20);
        }
    }

    &-copy {
       color: #5097FF;
        font-size: gem(24);
        line-height: 150%;
        &_mobile{
           display: inline; 
        }
    }
}
}
@media (max-width: 480px) {
    .footer {
    &-wrap {
        padding-top: gem(60);
        padding-bottom: gem(60);
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: gem(30);
        margin-left: gem(20);
    }

    &-logo {
        
        align-items: flex-start;
       &-svg{
width: gem(220);
        height: gem(20);
        }
    }

    &-copy {
       color: #5097FF;
        font-size: gem(24);
        line-height: 150%;
        &_mobile{
           display: inline; 
        }
    }
}
}