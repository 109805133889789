@import "./components/footer";
@import "./components/header";
@import "./vendor/normalize.css";
@import "./common/rem.scss";
@import "./common/container.scss";
.page__body {
	background-image: url(../img/svg/bg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	font-family: Dosis;
}

:root {
	scroll-behavior: smooth;
}

main section {
	scroll-margin-top: 60px;
}

@import "./components/header";
@import "./blocks/banner";
@import "./blocks/identity";
@import "./blocks/about";
@import "./blocks/services";
@import "./blocks/advantages";
@import "./blocks/growth";
@import "./blocks/contact";
@import "./components/footer";
