.header {
	position: relative;
	z-index: 99;

	&-wrap {
		max-width: gem(1200);
		padding-top: gem(30);
		padding-bottom: gem(30);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-logo-svg {
		display: flex;
		align-items: center;
	}

	&-menu-list {
		padding: 0;
		display: flex;
		margin: 0;
		gap: gem(32);

		&_item {
			display: flex;
			align-items: center;

			&-link {
				text-decoration: none;
				color: #fff;
				font-size: gem(15);
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: gem(0.3);

				&:hover,
				&:focus {
					color: #5097ff;
					text-decoration: underline;
				}
			}
		}
	}

	&-mobile {
		display: none;
	}
}

.burger {
	display: none;
}

@media (max-width: 1680px) {
	.header {
		&-wrap {
			max-width: 100%;
		}
	}
}

@media (max-width: 1263px) {
	.header {
		&-logo {
			&-svg {
				&_img {
					width: gem(220);
					height: gem(20);
				}

			}
		}

		&-menu-list {
			gap: gem(20);

			&_item {

				&-link {
					font-size: gem(12);
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.header {
		background: rgba(0, 23, 56, 0.96);
		width: 100%;
		position: sticky;
		top: 0;
		left: 0;

		&-menu-list {
			display: none;
		}

		&-mobile {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.9);
			visibility: hidden;
// &.active{
// 	.burger-icon_open{
// 		display: none;
// 	}
// }
			&-menu {
				position: absolute;
				right: 0;
				top: gem(84);
				overflow: hidden;
				border-radius: 20px 0px 20px 20px;
				background: #fff;
				width: max-content;
				display: flex;
				flex-direction: column;
				padding: gem(40);
				align-items: flex-start;
				gap: gem(10);
				transform: translateX(100vh);
				transition: all 0.3s ease;
			}

			&-close {
				position: absolute;
				right: 0;
				top: gem(20);
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 0;
				background-color: transparent;
				cursor: pointer;
				border: none;
				outline: none;
				transform: translateX(100vh);
				transition: all 0.3s ease;
			}

			&.active {
				visibility: visible;

				.header-mobile-menu {
					transform: translateX(0);
				}

				.header-mobile-close {
					transform: translateX(0);
				}
			}
		}
	}

	.burger {
		display: block;
		position: relative;

		&-icon_open {
			display: flex;
			background-color: transparent;
			border: none;
		}

		&-icon_close {
			display: flex;
			align-items: center;
			justify-content: center;
			display: none;
			margin: 0;
			padding: 0;
			background-color: transparent;
			cursor: pointer;
			border: none;
			outline: none;
		}

		&-icon_close.is-open {
			display: flex;
		}

		&-menu-container {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}

		&-menu-container {
			z-index: 100;
			position: absolute;
			right: 0;
			top: gem(40);
			overflow: hidden;
			opacity: 1;
			border-radius: 20px 0px 20px 20px;
			background: #fff;
		}

		&-menu-list {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding: gem(40);
			align-items: flex-start;
			gap: gem(10);
		}

		&-menu_item {
			display: flex;
		}

		&-menu_link {
			outline: none;
			color: #001738;
			font-size: 15px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.3px;
			text-decoration: none;

			box-sizing: border-box;
			display: block;
			width: 100%;
			white-space: nowrap;

			&:hover,
			&:focus {
				color: #5097ff;
				text-decoration: underline;
			}
		}
	}

}
.burger-icon_open.active{
display: none;
}
.header-burger_container{
	height: 100dvh;
	position: relative;

}

@media (max-width: 480px) {
	.header {

		&-wrap {
			max-width: 100%;
			padding-top: gem(20);
			padding-bottom: gem(20);
		}


		&-menu-list {
			display: none;
		}

		.burger {
			display: block;
			position: relative;

			&-menu-container {
				opacity: 0;
				pointer-events: none;
				visibility: hidden;
			}

			&-menu-list {
				z-index: 100;
				position: absolute;
				right: 0;
				top: 40px;
				overflow: hidden;
				opacity: 0;
				visibility: hidden;
				border-radius: 20px 0px 20px 20px;
				background: #fff;
				width: max-content;
				display: flex;
				flex-direction: column;
				padding: 40px;
				align-items: flex-start;
				gap: 10px;
			}

			&-menu_item {
				display: flex;
			}

			&-menu_link {
				outline: none;
				color: #001738;
				font-size: 15px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.3px;
				text-decoration: none;

				box-sizing: border-box;
				display: block;
				width: 100%;
				white-space: nowrap;

				&:hover,
				&:focus {
					color: #5097ff;
					text-decoration: underline;
				}
			}
		}&-mobile-menu{
			top: gem(60);
		}
	}
	
}