@import "./vendor/normalize.css";
.footer {
  width: 100%;
  background: rgba(255, 255, 255, 0.03);
}
.footer-wrap {
  padding-top: gem(75);
  padding-bottom: gem(75);
}
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-logo {
  display: flex;
}
.footer-copy {
  color: #FFF;
  font-size: gem(32);
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.footer-copy_mobile {
  display: none;
}

@media (max-width: 1024px) {
  .footer-wrap {
    padding-top: gem(60);
    padding-bottom: gem(60);
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: gem(30);
    margin-left: gem(20);
  }
  .footer-logo {
    align-items: flex-start;
  }
  .footer-logo-svg {
    width: gem(220);
    height: gem(20);
  }
  .footer-copy {
    color: #5097FF;
    font-size: gem(24);
    line-height: 150%;
  }
  .footer-copy_mobile {
    display: inline;
  }
}
@media (max-width: 480px) {
  .footer-wrap {
    padding-top: gem(60);
    padding-bottom: gem(60);
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: gem(30);
    margin-left: gem(20);
  }
  .footer-logo {
    align-items: flex-start;
  }
  .footer-logo-svg {
    width: gem(220);
    height: gem(20);
  }
  .footer-copy {
    color: #5097FF;
    font-size: gem(24);
    line-height: 150%;
  }
  .footer-copy_mobile {
    display: inline;
  }
}
.header {
  position: relative;
  z-index: 99;
}
.header-wrap {
  max-width: gem(1200);
  padding-top: gem(30);
  padding-bottom: gem(30);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo-svg {
  display: flex;
  align-items: center;
}
.header-menu-list {
  padding: 0;
  display: flex;
  margin: 0;
  gap: gem(32);
}
.header-menu-list_item {
  display: flex;
  align-items: center;
}
.header-menu-list_item-link {
  text-decoration: none;
  color: #fff;
  font-size: gem(15);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: gem(0.3);
}
.header-menu-list_item-link:hover, .header-menu-list_item-link:focus {
  color: #5097ff;
  text-decoration: underline;
}
.header-mobile {
  display: none;
}

.burger {
  display: none;
}

@media (max-width: 1680px) {
  .header-wrap {
    max-width: 100%;
  }
}
@media (max-width: 1263px) {
  .header-logo-svg_img {
    width: gem(220);
    height: gem(20);
  }
  .header-menu-list {
    gap: gem(20);
  }
  .header-menu-list_item-link {
    font-size: gem(12);
  }
}
@media (max-width: 1024px) {
  .header {
    background: rgba(0, 23, 56, 0.96);
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
  }
  .header-menu-list {
    display: none;
  }
  .header-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    visibility: hidden;
  }
  .header-mobile-menu {
    position: absolute;
    right: 0;
    top: gem(84);
    overflow: hidden;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: gem(40);
    align-items: flex-start;
    gap: gem(10);
    transform: translateX(100vh);
    transition: all 0.3s ease;
  }
  .header-mobile-close {
    position: absolute;
    right: 0;
    top: gem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    transform: translateX(100vh);
    transition: all 0.3s ease;
  }
  .header-mobile.active {
    visibility: visible;
  }
  .header-mobile.active .header-mobile-menu {
    transform: translateX(0);
  }
  .header-mobile.active .header-mobile-close {
    transform: translateX(0);
  }
  .burger {
    display: block;
    position: relative;
  }
  .burger-icon_open {
    display: flex;
    background-color: transparent;
    border: none;
  }
  .burger-icon_close {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .burger-icon_close.is-open {
    display: flex;
  }
  .burger-menu-container {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .burger-menu-container {
    z-index: 100;
    position: absolute;
    right: 0;
    top: gem(40);
    overflow: hidden;
    opacity: 1;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
  }
  .burger-menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: gem(40);
    align-items: flex-start;
    gap: gem(10);
  }
  .burger-menu_item {
    display: flex;
  }
  .burger-menu_link {
    outline: none;
    color: #001738;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
  .burger-menu_link:hover, .burger-menu_link:focus {
    color: #5097ff;
    text-decoration: underline;
  }
}
.burger-icon_open.active {
  display: none;
}

.header-burger_container {
  height: 100dvh;
  position: relative;
}

@media (max-width: 480px) {
  .header-wrap {
    max-width: 100%;
    padding-top: gem(20);
    padding-bottom: gem(20);
  }
  .header-menu-list {
    display: none;
  }
  .header .burger {
    display: block;
    position: relative;
  }
  .header .burger-menu-container {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .header .burger-menu-list {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 40px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 40px;
    align-items: flex-start;
    gap: 10px;
  }
  .header .burger-menu_item {
    display: flex;
  }
  .header .burger-menu_link {
    outline: none;
    color: #001738;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
  .header .burger-menu_link:hover, .header .burger-menu_link:focus {
    color: #5097ff;
    text-decoration: underline;
  }
  .header-mobile-menu {
    top: gem(60);
  }
}
html {
  font-size: calc(10000vw / 1680);
}

@media all and (max-width: 1263px) {
  html {
    font-size: calc(10000vw / 1143);
  }
}
@media all and (max-width: 1024px) {
  html {
    font-size: calc(10000vw / 768);
  }
}
@media all and (max-width: 480px) {
  html {
    font-size: calc(10000vw / 360);
  }
}
@media (min-width: 1680px) {
  .container {
    width: 12rem;
    padding: 0 0.15rem;
    margin: 0 auto;
  }
}
@media (max-width: 1680px) {
  .container {
    width: 13rem;
    padding: 0 0.15rem;
    margin: 0 auto;
  }
}
@media (max-width: 1263px) {
  .container {
    width: 9rem;
    padding: 0 0.15rem;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .container {
    width: 6.74rem;
    padding: 0 0.15rem;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .container {
    width: 3.2rem;
    padding: 0px;
    margin: 0 auto;
  }
}
.page__body {
  background-image: url(../img/svg/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: Dosis;
}

:root {
  scroll-behavior: smooth;
}

main section {
  scroll-margin-top: 60px;
}

.header {
  position: relative;
  z-index: 99;
}
.header-wrap {
  max-width: 12rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo-svg {
  display: flex;
  align-items: center;
}
.header-menu-list {
  padding: 0;
  display: flex;
  margin: 0;
  gap: 0.32rem;
}
.header-menu-list_item {
  display: flex;
  align-items: center;
}
.header-menu-list_item-link {
  text-decoration: none;
  color: #fff;
  font-size: 0.15rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.003rem;
}
.header-menu-list_item-link:hover, .header-menu-list_item-link:focus {
  color: #5097ff;
  text-decoration: underline;
}
.header-mobile {
  display: none;
}

.burger {
  display: none;
}

@media (max-width: 1680px) {
  .header-wrap {
    max-width: 100%;
  }
}
@media (max-width: 1263px) {
  .header-logo-svg_img {
    width: 2.2rem;
    height: 0.2rem;
  }
  .header-menu-list {
    gap: 0.2rem;
  }
  .header-menu-list_item-link {
    font-size: 0.12rem;
  }
}
@media (max-width: 1024px) {
  .header {
    background: rgba(0, 23, 56, 0.96);
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
  }
  .header-menu-list {
    display: none;
  }
  .header-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    visibility: hidden;
  }
  .header-mobile-menu {
    position: absolute;
    right: 0;
    top: 0.84rem;
    overflow: hidden;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 0.4rem;
    align-items: flex-start;
    gap: 0.1rem;
    transform: translateX(100vh);
    transition: all 0.3s ease;
  }
  .header-mobile-close {
    position: absolute;
    right: 0;
    top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    transform: translateX(100vh);
    transition: all 0.3s ease;
  }
  .header-mobile.active {
    visibility: visible;
  }
  .header-mobile.active .header-mobile-menu {
    transform: translateX(0);
  }
  .header-mobile.active .header-mobile-close {
    transform: translateX(0);
  }
  .burger {
    display: block;
    position: relative;
  }
  .burger-icon_open {
    display: flex;
    background-color: transparent;
    border: none;
  }
  .burger-icon_close {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .burger-icon_close.is-open {
    display: flex;
  }
  .burger-menu-container {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .burger-menu-container {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0.4rem;
    overflow: hidden;
    opacity: 1;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
  }
  .burger-menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.4rem;
    align-items: flex-start;
    gap: 0.1rem;
  }
  .burger-menu_item {
    display: flex;
  }
  .burger-menu_link {
    outline: none;
    color: #001738;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
  .burger-menu_link:hover, .burger-menu_link:focus {
    color: #5097ff;
    text-decoration: underline;
  }
}
.burger-icon_open.active {
  display: none;
}

.header-burger_container {
  height: 100dvh;
  position: relative;
}

@media (max-width: 480px) {
  .header-wrap {
    max-width: 100%;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .header-menu-list {
    display: none;
  }
  .header .burger {
    display: block;
    position: relative;
  }
  .header .burger-menu-container {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .header .burger-menu-list {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 40px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    border-radius: 20px 0px 20px 20px;
    background: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 40px;
    align-items: flex-start;
    gap: 10px;
  }
  .header .burger-menu_item {
    display: flex;
  }
  .header .burger-menu_link {
    outline: none;
    color: #001738;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
  .header .burger-menu_link:hover, .header .burger-menu_link:focus {
    color: #5097ff;
    text-decoration: underline;
  }
  .header-mobile-menu {
    top: 0.6rem;
  }
}
.banner {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: -0.84rem;
  height: 100%;
}
.banner-wrapper {
  padding-top: 2.02rem;
  padding-bottom: 1.43rem;
  position: relative;
  z-index: 10;
}
.banner-title {
  margin: 0;
  color: #fff;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.96rem;
  letter-spacing: 0.0096rem;
}
.banner-title-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-left: 2.1rem;
  margin-bottom: 0.4rem;
}
.banner-title_blue {
  margin-left: 0.77rem;
  color: #5097ff;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.96rem;
  letter-spacing: 0.0192rem;
  text-transform: uppercase;
}
.banner-text {
  margin-left: 4.69rem;
  color: #fff;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 200;
  line-height: 0.62rem;
}
.banner-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.banner-text_blue {
  margin-left: 3rem;
  color: #5097ff;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 300;
  line-height: 0.62rem;
}
.banner-bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
}

@media (max-width: 1263px) {
  .banner-title-wrap {
    margin-left: 0rem;
  }
  .banner-text {
    text-align: right;
    margin-left: 0rem;
  }
  .banner-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  .banner-text_blue {
    margin-left: 0rem;
  }
  .banner-bg {
    width: 100%;
    object-fit: cover;
  }
}
@media (max-width: 1024px) {
  .banner {
    margin-top: 0;
  }
  .banner-wrapper {
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
  }
  .banner-title {
    margin: 0;
  }
  .banner-title-wrap {
    gap: 0.5rem;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  .banner-title_blue {
    margin-left: 0.1rem;
  }
  .banner-text {
    text-align: right;
    margin-left: 0rem;
  }
  .banner-text-wrap {
    gap: 0.5rem;
  }
  .banner-text_blue {
    margin-left: 0rem;
  }
  .banner-bg {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .banner {
    margin-top: 0;
  }
  .banner-wrapper {
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
  }
  .banner-title {
    margin: 0;
    font-size: 0.5rem;
    line-height: 100%;
    letter-spacing: 0.005rem;
  }
  .banner-title-wrap {
    gap: 0.5rem;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  .banner-title_blue {
    margin-left: 0.1rem;
    font-size: 0.45rem;
    line-height: 100%;
    letter-spacing: 0.01rem;
  }
  .banner-text {
    text-align: right;
    margin-left: 0rem;
    font-size: 0.43rem;
    line-height: 100%;
  }
  .banner-text-wrap {
    gap: 0.5rem;
  }
  .banner-text_blue {
    margin-left: 0rem;
    font-size: 0.43rem;
    line-height: 130%;
  }
  .banner-bg {
    width: 100%;
  }
}
.identity {
  background: rgba(255, 255, 255, 0.03);
  width: 100%;
}
.identity-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.identity-title {
  margin-bottom: 0.5rem;
  color: #FFF;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.identity-title_blue {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  color: #5097FF;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.identity-text {
  margin-left: 1rem;
  color: #FFF;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 1024px) {
  .identity-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .identity-title {
    margin-bottom: 0.3rem;
  }
  .identity-title_blue {
    margin-bottom: 0.3rem;
    margin-left: 0.2rem;
  }
  .identity-text {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
@media (max-width: 480px) {
  .identity-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .identity-title {
    margin-bottom: 0.3rem;
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .identity-title_blue {
    margin-bottom: 0.3rem;
    margin-left: 0.2rem;
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .identity-text {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    font-size: 0.24rem;
  }
}
.about {
  width: 100%;
}
.about-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.about-title {
  color: #fff;
  margin-bottom: 0.5rem;
  text-align: right;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.about-title_blue {
  margin-left: 0.2rem;
  color: #5097ff;
  text-align: center;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.about-text {
  color: #fff;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.about-list {
  display: flex;
  gap: 0.2rem;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.about-list_item {
  flex: 1;
  padding: 0.5rem 0.28rem;
  border-radius: 0.15rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}
.about-list_item:hover, .about-list_item:focus {
  transform: translateY(-5px);
}
.about-list_item-num {
  color: #fff;
  text-align: center;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0072rem;
  margin-bottom: 0.3rem;
}
.about-list_item-text {
  color: #5097ff;
  text-align: center;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.0108rem;
}

@media (max-width: 1024px) {
  .about-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .about-title {
    margin-bottom: 0.3rem;
  }
  .about-title_blue {
    margin-left: 0.1rem;
  }
  .about-text {
    margin: 0 0.2rem 0 0.2rem;
  }
  .about-list {
    flex-direction: column;
    gap: 0.3rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .about-list_item {
    padding: 0.3rem 0.2rem;
  }
}
@media (max-width: 480px) {
  .about-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .about-title {
    margin-bottom: 0.3rem;
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .about-title_blue {
    margin-left: 0.1rem;
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .about-text {
    font-size: 0.24rem;
    margin: 0 0.2rem 0 0.2rem;
  }
  .about-list {
    flex-direction: column;
    gap: 0.3rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .about-list_item {
    padding: 0.3rem 0.2rem;
  }
  .about-list_item-num {
    font-size: 0.48rem;
    letter-spacing: 0.0048rem;
  }
  .about-list_item-text {
    font-size: 0.32rem;
    letter-spacing: 0.0096rem;
  }
}
.services {
  background: rgba(255, 255, 255, 0.03);
  width: 100%;
}
.services-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.services-title {
  margin-bottom: 0.5rem;
  color: #fff;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.services-title_blue {
  color: #5097ff;
  text-align: center;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.services-card {
  display: flex;
  gap: 0.5rem;
}
.services-card-item {
  display: flex;
  padding: 0.5rem 0.28rem;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  border-radius: 0.15rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}
.services-card-item:hover, .services-card-item:focus {
  transform: translateY(-5px);
}
.services-card-item-title {
  color: #fff;
  text-align: center;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.services-card-item-text {
  color: #5097ff;
  text-align: center;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.0072rem;
}

@media (max-width: 1024px) {
  .services-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .services-title {
    margin-bottom: 0.3rem;
  }
  .services-card {
    flex-direction: column;
    gap: 0.3rem;
  }
  .services-card-item {
    padding: 0.3rem 0.3rem;
    gap: 0.3rem;
  }
}
@media (max-width: 480px) {
  .services-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .services-title {
    margin-bottom: 0.3rem;
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .services-title_blue {
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .services-card {
    flex-direction: column;
    gap: 0.3rem;
  }
  .services-card-item {
    padding: 0.3rem 0.3rem;
    gap: 0.3rem;
  }
  .services-card-item-title {
    font-size: 0.24rem;
    letter-spacing: 0.0072rem;
  }
  .services-card-item-text {
    font-size: 0.22rem;
    line-height: 140%;
    letter-spacing: 0.0066rem;
  }
}
.advantages {
  width: 100%;
}
.advantages-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.advantages-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.advantages-title {
  text-align: end;
  color: #fff;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.advantages-title_mobile {
  display: none;
}
.advantages-title_blue {
  text-align: end;
  color: #5097ff;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.advantages-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.advantages-card-item {
  display: flex;
  padding: 0.25rem;
  gap: 0.25rem;
  border-radius: 0.15rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}
.advantages-card-item:hover, .advantages-card-item:focus {
  transform: translateY(-5px);
}
.advantages-card-item-title {
  margin-bottom: 0.1rem;
  color: #fff;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.advantages-card-item-text {
  color: #5097ff;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}

@media (max-width: 1024px) {
  .advantages-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .advantages-content {
    gap: 0.3rem;
  }
  .advantages-title {
    text-align: end;
  }
  .advantages-title_mobile {
    display: inline;
  }
  .advantages-card {
    gap: 0.3rem;
  }
  .advantages-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.3rem;
    gap: 0.3rem;
  }
  .advantages-card-item-title {
    margin-bottom: 0.3rem;
  }
}
@media (max-width: 480px) {
  .advantages-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .advantages-content {
    gap: 0.3rem;
  }
  .advantages-title {
    text-align: end;
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .advantages-title_mobile {
    display: inline;
  }
  .advantages-title_blue {
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .advantages-card {
    gap: 0.3rem;
  }
  .advantages-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.3rem;
    gap: 0.3rem;
  }
  .advantages-card-item-title {
    margin-bottom: 0.3rem;
    font-size: 0.22rem;
    letter-spacing: 0.0066rem;
  }
  .advantages-card-item-text {
    font-size: 0.22rem;
    line-height: 130%;
    letter-spacing: 0.0066rem;
  }
}
.growth {
  background: rgba(255, 255, 255, 0.03);
  width: 100%;
}
.growth-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.growth-title {
  color: #fff;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.growth-title_blue {
  margin-left: 0.5rem;
  color: #5097ff;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.growth-text {
  color: #fff;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.growth-text-wrap {
  margin-left: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.growth-text_blue {
  color: #5097ff;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.growth-text-btn {
  outline: none;
  margin-left: 1rem;
  text-decoration: none;
  width: 4.6rem;
  height: 0.8rem;
  padding: 0.27rem 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.15rem;
  background: #1e77f9;
  color: #fff;
  text-align: center;
  font-size: 0.35rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0108rem;
  transition: all 0.2s;
}
.growth-text-btn:hover, .growth-text-btn:focus {
  transform: translateY(-5px);
}

@media (max-width: 1024px) {
  .growth-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    gap: 0.3rem;
  }
  .growth-title_blue {
    margin-left: 0.2rem;
  }
  .growth-text-wrap {
    margin-bottom: 0;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    gap: 0.3rem;
  }
  .growth-text-btn {
    margin-left: 0.1rem;
  }
}
@media (max-width: 480px) {
  .growth-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    gap: 0.3rem;
  }
  .growth-title {
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .growth-title_blue {
    margin-left: 0.2rem;
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .growth-text {
    font-size: 0.24rem;
  }
  .growth-text-wrap {
    margin-bottom: 0;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    gap: 0.3rem;
  }
  .growth-text_blue {
    font-size: 0.24rem;
  }
  .growth-text-btn {
    width: calc(100% - 0.1rem);
    margin-left: 0.1rem;
    height: 0.64rem;
    padding: 0.24rem 0.3rem;
    font-size: 0.22rem;
    letter-spacing: 0.0066rem;
  }
}
.contact {
  width: 100%;
}
.contact-wrap {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}
.contact-title {
  color: #fff;
  text-align: right;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0072rem;
}
.contact-title_blue {
  color: #5097ff;
  text-align: center;
  font-size: 0.72rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.0144rem;
  text-transform: uppercase;
}
.contact-title-wrap {
  margin-bottom: 0.5rem;
  gap: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.contact-card {
  margin-bottom: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
}
.contact-card-item {
  display: flex;
  flex: 1;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  border-radius: 0.15rem;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}
.contact-card-item:hover, .contact-card-item:focus {
  transform: translateY(-5px);
}
.contact-card-item-title {
  color: #5097ff;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.0108rem;
}
.contact-card-item-text {
  text-decoration: none;
  color: #fff;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.contact-text {
  color: #5097ff;
  font-size: 0.48rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.0096rem;
}

@media (max-width: 1024px) {
  .contact-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .contact-title-wrap {
    margin-bottom: 0.3rem;
    gap: 0.1rem;
  }
  .contact-card {
    margin-bottom: 0.3rem;
    gap: 0.3rem;
    flex-direction: column;
  }
  .contact-card-item {
    padding: 0.3rem;
    gap: 0.2rem;
  }
  .contact-text {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
@media (max-width: 480px) {
  .contact-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .contact-title {
    font-size: 0.36rem;
    letter-spacing: 0.0036rem;
  }
  .contact-title_blue {
    font-size: 0.36rem;
    letter-spacing: 0.0072rem;
  }
  .contact-title-wrap {
    margin-bottom: 0.3rem;
    gap: 0.1rem;
  }
  .contact-card {
    margin-bottom: 0.3rem;
    gap: 0.3rem;
    flex-direction: column;
  }
  .contact-card-item {
    padding: 0.3rem;
    gap: 0.2rem;
  }
  .contact-card-item-title {
    font-size: 0.24rem;
    letter-spacing: 0.0072rem;
  }
  .contact-card-item-text {
    font-size: 0.24rem;
  }
  .contact-text {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    font-size: 0.24rem;
    font-weight: 600;
  }
}
.footer {
  width: 100%;
  background: rgba(255, 255, 255, 0.03);
}
.footer-wrap {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-logo {
  display: flex;
}
.footer-copy {
  color: #FFF;
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.footer-copy_mobile {
  display: none;
}

@media (max-width: 1024px) {
  .footer-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    margin-left: 0.2rem;
  }
  .footer-logo {
    align-items: flex-start;
  }
  .footer-logo-svg {
    width: 2.2rem;
    height: 0.2rem;
  }
  .footer-copy {
    color: #5097FF;
    font-size: 0.24rem;
    line-height: 150%;
  }
  .footer-copy_mobile {
    display: inline;
  }
}
@media (max-width: 480px) {
  .footer-wrap {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    margin-left: 0.2rem;
  }
  .footer-logo {
    align-items: flex-start;
  }
  .footer-logo-svg {
    width: 2.2rem;
    height: 0.2rem;
  }
  .footer-copy {
    color: #5097FF;
    font-size: 0.24rem;
    line-height: 150%;
  }
  .footer-copy_mobile {
    display: inline;
  }
}