.banner {
	width: 100%;
	position: relative;
	z-index: 1;
	margin-top: gem(-84);
	height: 100%;

	&-wrapper {
		padding-top: gem(202);
		padding-bottom: gem(143);
		position: relative;
		z-index: 10;
	}

	&-title {
		margin: 0;
		color: #fff;
		font-size: gem(96);
		font-style: normal;
		font-weight: 600;
		line-height: gem(96);
		letter-spacing: gem(0.96);

		&-wrap {
			display: flex;
			flex-direction: column;
			gap: gem(40);
			margin-left: gem(210);
			margin-bottom: gem(40);
		}

		&_blue {
			margin-left: gem(77);
			color: #5097ff;
			font-size: gem(96);
			font-style: normal;
			font-weight: 700;
			line-height: gem(96);
			letter-spacing: gem(1.92);
			text-transform: uppercase;
		}
	}

	&-text {
		margin-left: gem(469);
		color: #fff;
		font-size: gem(62);
		font-style: normal;
		font-weight: 200;
		line-height: gem(62);

		&-wrap {
			display: flex;
			flex-direction: column;
			gap: gem(40);
		}

		&_blue {
			margin-left: gem(300);
			color: #5097ff;
			font-size: gem(62);
			font-style: normal;
			font-weight: 300;
			line-height: gem(62);
		}
	}

	&-bg {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		bottom: 0;
		height: 100%;
		z-index: 0;
	}
}

@media (max-width: 1263px) {
	.banner {
		&-title {

			&-wrap {

				margin-left: gem(0);
			}

		}

		&-text {
			text-align: right;
			margin-left: gem(0);


			&-wrap {
				display: flex;
				flex-direction: column;
				gap: gem(40);
			}

			&_blue {
				margin-left: gem(0);

			}
		}

		&-bg {
			width: 100%;
			object-fit: cover;
		}

	}
}

@media (max-width: 1024px) {
	.banner {
		margin-top: 0;

		&-wrapper {
			padding-top: gem(60);
			padding-bottom: gem(50);

		}

		&-title {
			margin: 0;


			&-wrap {
				gap: gem(50);
				margin-left: 0;
				margin-bottom: gem(50);
			}

			&_blue {
				margin-left: gem(10);

			}
		}

		&-text {
			text-align: right;
			margin-left: gem(0);


			&-wrap {
				gap: gem(50);
			}

			&_blue {
				margin-left: gem(0);

			}
		}

		&-bg {
			width: 100%;
		}
	}
}

@media (max-width: 480px) {
	.banner {
		margin-top: 0;

		&-wrapper {
			padding-top: gem(60);
			padding-bottom: gem(50);

		}

		&-title {
			margin: 0;
			font-size: gem(50);
			line-height: 100%;
			letter-spacing: gem(0.5);

			&-wrap {
				gap: gem(50);
				margin-left: 0;
				margin-bottom: gem(50);
			}

			&_blue {
				margin-left: gem(10);
				font-size: gem(45);
				line-height: 100%;
				letter-spacing: gem(1);
			}
		}

		&-text {
			text-align: right;
			margin-left: gem(0);
			font-size: gem(43);
			line-height: 100%;

			&-wrap {
				gap: gem(50);
			}

			&_blue {
				margin-left: gem(0);
				font-size: gem(43);
				line-height: 130%;
			}
		}

		&-bg {
			width: 100%;
		}
	}
}