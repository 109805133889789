.contact {
	width: 100%;

	&-wrap {
		padding-top: gem(140);
		padding-bottom: gem(140);
	}

	&-title {
		color: #fff;
		text-align: right;
		font-size: gem(72);
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		letter-spacing: gem(0.72);

		&_blue {
			color: #5097ff;
			text-align: center;
			font-size: gem(72);
			font-style: normal;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: gem(1.44);
			text-transform: uppercase;
		}

		&-wrap {
			margin-bottom: gem(50);
			gap: gem(20);
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	&-card {
		margin-bottom: gem(50);
		gap: gem(50);
		display: flex;
		flex-direction: row;

		&-item {
			display: flex;
			flex: 1;
			padding: gem(50);
			flex-direction: column;
			align-items: flex-start;
			gap: gem(30);

			border-radius: gem(15);
			background: rgba(255, 255, 255, 0.1);

			transition: all 0.2s;

			&:hover,
			&:focus {
				transform: translateY(-5px);
			}

			&-title {
				color: #5097ff;
				font-size: gem(36);
				font-style: normal;
				font-weight: 600;
				line-height: 100%;
				letter-spacing: gem(1.08);
			}

			&-text {
				text-decoration: none;
				color: #fff;
				font-size: gem(36);
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
			}
		}
	}

	&-text {
		color: #5097ff;
		font-size: gem(48);
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: gem(0.96);
	}
}

@media (max-width: 1024px) {
	.contact {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {


			&-wrap {
				margin-bottom: gem(30);
				gap: gem(10);
			}
		}

		&-card {
			margin-bottom: gem(30);
			gap: gem(30);
			flex-direction: column;

			&-item {

				padding: gem(30);

				gap: gem(20);

			}
		}

		&-text {
			margin-left: gem(30);
			margin-right: gem(30);

		}
	}
}

@media (max-width: 480px) {
	.contact {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {
			font-size: gem(36);
			letter-spacing: gem(0.36);

			&_blue {
				font-size: gem(36);
				letter-spacing: gem(0.72);
			}

			&-wrap {
				margin-bottom: gem(30);
				gap: gem(10);
			}
		}

		&-card {
			margin-bottom: gem(30);
			gap: gem(30);
			flex-direction: column;

			&-item {

				padding: gem(30);

				gap: gem(20);

				&-title {
					font-size: gem(24);
					letter-spacing: gem(0.72);
				}

				&-text {
					font-size: gem(24);
				}
			}
		}

		&-text {
			margin-left: gem(30);
			margin-right: gem(30);
			font-size: gem(24);
			font-weight: 600;
		}
	}
}