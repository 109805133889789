.about {
	width: 100%;

	&-wrap {
		padding-top: gem(140);
		padding-bottom: gem(140);
	}

	&-title {
		color: #fff;
		margin-bottom: gem(50);
		text-align: right;
		font-size: gem(72);
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		letter-spacing: gem(0.72);

		&_blue {
			margin-left: gem(20);
			color: #5097ff;
			text-align: center;
			font-size: gem(72);
			font-style: normal;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: gem(1.44);
			text-transform: uppercase;
		}
	}

	&-text {
		color: #fff;
		font-size: gem(36);
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	&-list {
		display: flex;
		gap: gem(20);
		width: 100%;
		margin-top: gem(50);
		margin-bottom: gem(50);



		&_item {
			flex: 1;
			padding: gem(50) gem(28);
			border-radius: gem(15);
			background: rgba(255, 255, 255, 0.1);
			transition: all 0.2s;

			&:hover,
			&:focus {
				transform: translateY(-5px);
			}

			&-num {
				color: #fff;
				text-align: center;
				font-size: gem(72);
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: gem(0.72);
				margin-bottom: gem(30);
			}

			&-text {
				color: #5097ff;
				text-align: center;
				font-size: gem(36);
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				letter-spacing: gem(1.08);
			}
		}
	}
}

@media (max-width: 1024px) {
	.about {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {
			margin-bottom: gem(30);

			&_blue {
				margin-left: gem(10);
			}
		}

		&-text {
			margin: 0 gem(20) 0 gem(20);
		}

		&-list {
			flex-direction: column;
			gap: gem(30);
			margin-top: gem(30);
			margin-bottom: gem(30);

			&_item {
				padding: gem(30) gem(20);
			}
		}
	}
}

@media (max-width: 480px) {
	.about {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {
			margin-bottom: gem(30);
			font-size: gem(36);
			letter-spacing: gem(0.36);

			&_blue {
				margin-left: gem(10);
				font-size: gem(36);
				letter-spacing: gem(0.72);
			}
		}

		&-text {
			font-size: gem(24);
			margin: 0 gem(20) 0 gem(20);
		}

		&-list {
			flex-direction: column;
			gap: gem(30);
			margin-top: gem(30);
			margin-bottom: gem(30);

			&_item {
				padding: gem(30) gem(20);

				&-num {
					font-size: gem(48);
					letter-spacing: gem(0.48);
				}

				&-text {
					font-size: gem(32);
					letter-spacing: gem(0.96);
				}
			}
		}
	}
}