.growth {
	background: rgba(255, 255, 255, 0.03);
	width: 100%;

	&-wrap {
		padding-top: gem(140);
		padding-bottom: gem(140);
		display: flex;
		gap: gem(50);
		flex-direction: column;
	}

	&-title {
		color: #fff;
		font-size: gem(72);
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		letter-spacing: gem(0.72);

		&_blue {
			margin-left: gem(50);
			color: #5097ff;
			font-size: gem(72);
			font-style: normal;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: gem(1.44);
			text-transform: uppercase;
		}
	}

	&-text {
		color: #fff;
		font-size: gem(36);
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		&-wrap {
			margin-left: gem(100);
			display: flex;
			gap: gem(50);
			flex-direction: column;
		}

		&_blue {
			color: #5097ff;
			font-size: gem(36);
			font-style: normal;
			font-weight: 600;
			line-height: 100%;
		}

		&-btn {
			outline: none;
			margin-left: gem(100);
			text-decoration: none;
			width: gem(460);
			height: gem(80);
			padding: gem(27) gem(35);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: gem(15);
			background: #1e77f9;
			color: #fff;

			text-align: center;
			font-size: gem(35);
			font-style: normal;
			font-weight: 600;
			line-height: 100%;
			letter-spacing: gem(1.08);

			transition: all 0.2s;

			&:hover,
			&:focus {
				transform: translateY(-5px);
			}
		}
	}
}

@media (max-width: 1024px) {
	.growth {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
			gap: gem(30);
		}

		&-title {


			&_blue {
				margin-left: gem(20);

			}
		}

		&-text {

			&-wrap {
				margin-bottom: 0;
				margin-left: gem(20);
				margin-right: gem(20);
				gap: gem(30);
			}



			&-btn {
				margin-left: gem(10);

			}
		}
	}
}

@media (max-width: 480px) {
	.growth {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
			gap: gem(30);
		}

		&-title {
			font-size: gem(36);
			letter-spacing: gem(0.36);

			&_blue {
				margin-left: gem(20);
				font-size: gem(36);
				letter-spacing: gem(0.72);
			}
		}

		&-text {
			font-size: gem(24);

			&-wrap {
				margin-bottom: 0;
				margin-left: gem(20);
				margin-right: gem(20);
				gap: gem(30);
			}

			&_blue {
				font-size: gem(24);
			}

			&-btn {
				width: calc(100% - gem(10));
				margin-left: gem(10);
				height: gem(64);
				padding: gem(24) gem(30);

				font-size: gem(22);
				letter-spacing: gem(0.66);
			}
		}
	}
}