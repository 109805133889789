@media (min-width: 1680px) {
	.container {
		width: gem(1200);
		padding: 0 gem(15);
		margin: 0 auto;
	}
}
@media (max-width: 1680px) {
	.container {
		width: gem(1300);
		padding: 0 gem(15);
		margin: 0 auto;
	}
}
@media (max-width: 1263px) {
	.container {
		width: gem(900);
		padding: 0 gem(15);
		margin: 0 auto;
	}
}

@media (max-width: 1024px) {
	.container {
		width: gem(674);
		padding: 0 gem(15);
		margin: 0 auto;
	}
}
@media (max-width: 480px) {
	.container {
		width: gem(320);
		padding: 0px;
		margin: 0 auto;
	}
}
