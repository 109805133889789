.services {
	background: rgba(255, 255, 255, 0.03);
	width: 100%;

	&-wrap {
		padding-top: gem(140);
		padding-bottom: gem(140);
	}

	&-title {
		margin-bottom: gem(50);
		color: #fff;
		font-size: gem(72);
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		letter-spacing: gem(0.72);

		&_blue {
			color: #5097ff;
			text-align: center;
			font-size: gem(72);
			font-style: normal;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: gem(1.44);
			text-transform: uppercase;
		}
	}

	&-card {
		display: flex;
		gap: gem(50);


		&-item {
			display: flex;
			padding: gem(50) gem(28);
			flex-direction: column;
			align-items: center;
			gap: gem(30);
			border-radius: gem(15);
			background: rgba(255, 255, 255, 0.1);
			transition: all 0.2s;

			&:hover,
			&:focus {
				transform: translateY(-5px);
			}


			&-title {
				color: #fff;
				text-align: center;
				font-size: gem(24);
				font-style: normal;
				font-weight: 600;
				line-height: 100%;
				letter-spacing: gem(0.72);
			}

			&-text {
				color: #5097ff;
				text-align: center;
				font-size: gem(24);
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				letter-spacing: gem(0.72);
			}
		}
	}
}

@media (max-width: 1024px) {
	.services {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {
			margin-bottom: gem(30);

		}

		&-card {
			flex-direction: column;
			gap: gem(30);

			&-item {
				padding: gem(30) gem(30);
				gap: gem(30);
			}
		}
	}

}

@media (max-width: 480px) {
	.services {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-title {
			margin-bottom: gem(30);
			font-size: gem(36);
			letter-spacing: gem(0.36);

			&_blue {
				font-size: gem(36);
				letter-spacing: gem(0.72);
			}
		}

		&-card {
			flex-direction: column;
			gap: gem(30);

			&-item {
				padding: gem(30) gem(30);
				gap: gem(30);

				&-title {
					font-size: gem(24);
					letter-spacing: gem(0.72);
				}

				&-text {
					font-size: gem(22);
					line-height: 140%;
					letter-spacing: gem(0.66);
				}
			}
		}
	}
}