@import "./gem.scss";

@mixin remBase($widthDesign, $heightDesign) {
	html {
		font-size: calc(#{$gem-coeff * 100}vw / #{$widthDesign});
	}

	@if $heightDesign != 0 {
		@media all and (min-aspect-ratio: #{$widthDesign} / #{$heightDesign}) {
			html {
				font-size: calc(100vh / #{$heightDesign});
			}
		}
	}
}

@mixin remBreakpoint($widthDesign, $heightDesign, $query: "max-width") {
	@media all and (#{$query}: #{$widthDesign}px) {
		@include remBase($widthDesign, $heightDesign);
	}
}

@include remBase(1680, 0);

@media all and (max-width: 1263px) {
	@include remBase(1143, 0);
}

@media all and (max-width: 1024px) {
	@include remBase(768, 0);
}

@media all and (max-width: 480px) {
	@include remBase(360, 0);
}
