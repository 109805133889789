.advantages {
	width: 100%;

	&-wrap {
		padding-top: gem(140);
		padding-bottom: gem(140);
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: gem(50);
	}

	&-title {
		text-align: end;
		color: #fff;
		font-size: gem(72);
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		letter-spacing: gem(0.72);

		&_mobile {
			display: none;
		}

		&_blue {
			text-align: end;
			color: #5097ff;
			font-size: gem(72);
			font-style: normal;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: gem(1.44);
			text-transform: uppercase;
		}
	}

	&-card {
		display: flex;
		flex-direction: column;
		gap: gem(50);

		&-item {
			display: flex;
			padding: gem(25);
			gap: gem(25);
			border-radius: gem(15);
			background: rgba(255, 255, 255, 0.1);
			transition: all 0.2s;

			&:hover,
			&:focus {
				transform: translateY(-5px);
			}


			&-title {
				margin-bottom: gem(10);
				color: #fff;
				font-size: gem(24);
				font-style: normal;
				font-weight: 600;
				line-height: 100%;
				letter-spacing: gem(0.72);
			}

			&-text {
				color: #5097ff;
				font-size: gem(24);
				font-style: normal;
				font-weight: 400;
				line-height: 100%;
				letter-spacing: gem(0.72);
			}
		}
	}

}

@media (max-width: 1024px) {
	.advantages {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-content {
			gap: gem(30);
		}

		&-title {
			text-align: end;

			&_mobile {
				display: inline;
			}
		}

		&-card {
			gap: gem(30);

			&-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: gem(30);
				gap: gem(30);

				&-title {
					margin-bottom: gem(30);

				}

			}
		}

	}
}

@media (max-width: 480px) {
	.advantages {
		&-wrap {
			padding-top: gem(60);
			padding-bottom: gem(60);
		}

		&-content {
			gap: gem(30);
		}

		&-title {
			text-align: end;
			font-size: gem(36);
			letter-spacing: gem(0.36);

			&_mobile {
				display: inline;
			}

			&_blue {
				font-size: gem(36);
				letter-spacing: gem(0.72);
			}
		}

		&-card {
			gap: gem(30);

			&-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: gem(30);
				gap: gem(30);

				&-title {
					margin-bottom: gem(30);
					font-size: gem(22);
					letter-spacing: gem(0.66);
				}

				&-text {
					font-size: gem(22);
					line-height: 130%;
					letter-spacing: gem(0.66);
				}
			}
		}

	}
}