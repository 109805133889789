.identity {
    background: rgba(255, 255, 255, 0.03);
    width: 100%;

    &-wrap {
        padding-top: gem(140);
        padding-bottom: gem(140);
    }

    &-title {
        margin-bottom: gem(50);
        color: #FFF;
        font-size: gem(72);
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: gem(0.72);

        &_blue {
            margin-bottom: gem(50);
            margin-left: gem(50);
            color: #5097FF;
            font-size: gem(72);
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: gem(1.44);
            text-transform: uppercase;
        }
    }

    &-text {
        margin-left: gem(100);
        color: #FFF;
        font-size: gem(36);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

@media (max-width: 1024px) {
    .identity {
        &-wrap {
            padding-top: gem(60);
            padding-bottom: gem(60);
        }

        &-title {
            margin-bottom: gem(30);


            &_blue {
                margin-bottom: gem(30);
                margin-left: gem(20);

            }
        }

        &-text {
            margin-left: gem(20);
            margin-right: gem(20);

        }
    }

}

@media (max-width: 480px) {
    .identity {
        &-wrap {
            padding-top: gem(60);
            padding-bottom: gem(60);
        }

        &-title {
            margin-bottom: gem(30);
            font-size: gem(36);
            letter-spacing: gem(0.36);

            &_blue {
                margin-bottom: gem(30);
                margin-left: gem(20);
                font-size: gem(36);
                letter-spacing: gem(0.72);
            }
        }

        &-text {
            margin-left: gem(20);
            margin-right: gem(20);

            font-size: gem(24);
        }
    }

}